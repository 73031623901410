"use client";
import { useState } from 'react'
import { signIn } from "next-auth/react";
import LoadingDots from '@/components/icons/loading-dots'
import Github from '@/components/icons/github'

export default function LoginButtons() {
    const [signInClicked, setSignInClicked] = useState(false);

    return (
        <div className="flex flex-col space-y-4 bg-gray-50 px-4 py-8 md:px-16">
            <button
                disabled={signInClicked}
                className={`${signInClicked
                    ? "cursor-not-allowed border-gray-200 bg-gray-100"
                    : "border border-gray-200 bg-white text-black hover:bg-gray-50"
                    } flex h-10 w-full items-center justify-center space-x-3 rounded-md border text-sm shadow-sm transition-all duration-75 focus:outline-none`}
                onClick={() => {
                    setSignInClicked(true);
                    signIn("github", { callbackUrl: '/dashboard' });
                }}
            >
                {signInClicked ? (
                    <LoadingDots color="#808080" />
                ) : (
                    <>
                        <Github className="size-5" />
                        <p>Sign In with Github</p>
                    </>
                )}
            </button>
        </div>
    )
}
